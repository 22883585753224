import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { useSIPProvider } from "react-sipjs";
import { toast } from 'react-toastify';
import  CallMonitor  from './CallMonitor';
const SERVER_URL = window.BASE_WEB_SOCKET;
const BASE_DOMAIN = window.BASE_DOMAIN;

const LiveCalls = ({ 
  userId = '', 
  userType=2, 
  onTotalLiveCallsUpdate,
  BargeCall,
  WhisperCall,
  ListenCall,
  sipData={}
 }) => {
  const [calls, setCalls] = useState([]);
  const [socket, setSocket] = useState(null);
  const [activeCalls,setActiveCalls] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  // console.log({sipData})
  const {
    connectAndRegister,
    sessionManager,
    sessions,
    registerStatus,
    connectStatus,
    CONNECT_STATUS
  } = useSIPProvider();

  const updateCalls = useCallback((newCalls) => {
    console.log('Updating calls:', newCalls);
    setCalls(newCalls);
    setActiveCalls(newCalls?.activeCalls || [])
    if (onTotalLiveCallsUpdate) {
      onTotalLiveCallsUpdate(newCalls.length);
    }
  }, [onTotalLiveCallsUpdate]);

  useEffect(() => {
    console.log('Initializing socket connection');
    const newSocket = io(SERVER_URL, {
      withCredentials: true,
      transports: ['websocket']
    });

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');
      setConnectionStatus('Connected');
      if (userId) {
        console.log('Subscribing with userId:', userId);
        newSocket.emit('subscribe', userId);
      }
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      setConnectionStatus('Disconnected');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      setConnectionStatus('Error: ' + error.message);
    });

    newSocket.on('userCallsUpdate', (updatedCalls) => {
      console.log('Received userCallsUpdate:', updatedCalls);
      updateCalls(updatedCalls);
    });

    setSocket(newSocket);

    return () => {
      console.log('Disconnecting socket');
      newSocket.disconnect();
    };
  }, [userId, updateCalls]);

  useEffect(() => {
    if (socket && socket.connected && userId) {
      console.log('Subscribing with userId:', userId);
      socket.emit('subscribe', userId);
    }
  }, [socket, userId]);
  console.log({CONNECT_STATUS,connectStatus})

  // const BargeCall = async (ext) => {
  //   console.log({CONNECT_STATUS})
  //   if (connectStatus !== CONNECT_STATUS?.CONNECTED) {
  //     toast.error("You are not connected with any sip.");
  //     return;
  //   }
  //   await sessionManager?.call(`sip:552${ext}@${BASE_DOMAIN}`, {});
  // };

  // const WhisperCall = async (ext) => {
  //   if (connectStatus !== CONNECT_STATUS?.CONNECTED) {
  //     toast.error("You are not connected with any sip.");
  //     return;
  //   }
  //   await sessionManager?.call(`sip:553${ext}@${BASE_DOMAIN}`, {});
  // };

  // const ListenCall = async (ext) => {
  //   if (connectStatus !== CONNECT_STATUS?.CONNECTED) {
  //     toast.error("You are not connected with any sip.");
  //     return;
  //   }
  //   await sessionManager?.call(`sip:551${ext}@${BASE_DOMAIN}`, {});
  // };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Live Calls</h2>
     
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Caller ID</th>
              <th>Destination</th>
              <th>Status</th>
              <th>Extention</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* {JSON.stringify(calls)} */}
            {calls?.length === 0 ? <tr>
              <td colSpan={5} > No Calls Available</td>
            </tr>:""}
            {activeCalls?.map(call =>  call.runExt != sipData?.aors && (
              <tr key={call.uniqueId}>

                <td className='py-4 my-4'>{call.callerIdNum}</td>
                <td className='py-4 my-4' >{call.destinationNumber}</td>
                <td className='py-4 my-4'>
                  <span className={`badge ${call.status === 'Ring' ? 'bg-warning' : 'bg-success'}`}>
                    {call.status}
                  </span>
                </td>
                <td className='py-4 my-4'>
                  {call?.runExt}
                </td>
                <td className='py-4 my-4'>
                  <div className="btn-group" role="group">
                    <button className="btn btn-sm btn-primary" onClick={() => BargeCall(call?.runExt)}>Barge</button>
                    <button className="btn btn-sm btn-info" onClick={() => WhisperCall(call?.runExt)}>Whisper</button>
                    <button className="btn btn-sm btn-secondary" onClick={() => ListenCall(call?.runExt)}>Listen</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="mt-4">
        <h5>Debug Info:</h5>
        <pre className="bg-light p-3 rounded">
          {JSON.stringify({ calls, userId, userType }, null, 2)}
        </pre>
      </div> */}
    </div>
  );
};

export default LiveCalls;