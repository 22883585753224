import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import DataTable from "react-data-table-component";
import { tableCustomStyles } from '../../helper/utils';
// import { tableCustomStyles } from "../helper/utils";

const API_SERVER_URL = window.CAMPAIN_WWS_URL;
const FRONT_WEB_SOCKET_URL = "https://api.livepbxphone.us/"
// const BASE_API = "https://api.livepbxphone.us/";                             


const CampaignLiveCalls = ({ user_id }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user_id) {
      setError('User ID is required.');
      setLoading(false);
      return;
    }

    fetchLiveCalls(user_id);
  }, [user_id]);

  const fetchLiveCalls = (userId) => {
    axios.get(`${API_SERVER_URL}/api/live-calls`, { params: { user_id: userId } })
      .then((response) => {
        const allCalls = response.data;
        
        // Filter calls based on campaign_Id
        const filteredCampaigns = allCalls.map(campaign => ({
          ...campaign,
          calls: campaign.calls.filter(call => call.campaign_Id !== "NULL")
        })).filter(campaign => campaign.calls.length > 0);

        setCampaigns(filteredCampaigns);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching live calls:', err);
        setError('Failed to fetch live calls.');
        setLoading(false);
      });

    const socket = io(FRONT_WEB_SOCKET_URL);
    socket.emit('join', userId);

    socket.on('liveCalls', (data) => {
      const filteredCampaigns = data.map(campaign => ({
        ...campaign,
        calls: campaign.calls.filter(call => call.campaign_Id !== "NULL")
      })).filter(campaign => campaign.calls.length > 0);

      setCampaigns(filteredCampaigns);
    });

    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
    });

    return () => {
      socket.disconnect();
    };
  };

  const columns = [
    
    {
      name: "Call ID",
      selector: row => row.pk_id,
      sortable: true,
    },
    {
      name: "From",
      selector: row => row.call_from,
      sortable: true,
    },
    {
      name: "To",
      selector: row => row.call_to,
      sortable: true,
    },
    {
      name: "DID",
      selector: row => row.did,
      sortable: true,
    },
    {
      name: "Direction",
      selector: row => row.direction,
      sortable: true,
    },
    // {
    //   name: "Campaign ID",
    //   selector: row => row.campaign_Id,
    //   sortable: true,
    // },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="live-calls-container">
      {campaigns.length === 0 ? (
        <p>No live calls with campaign ID at the moment.</p>
      ) : (
        campaigns.map((campaign) => (
          <div key={campaign.campaignName} className="campaign-section">
            <h3>
              {campaign.campaignName} ({campaign.calls.length} {campaign.calls.length === 1 ? 'Call' : 'Calls'})
            </h3>
            <DataTable
              columns={columns}
              data={campaign.calls}
              pagination
              highlightOnHover
              customStyles={tableCustomStyles}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default CampaignLiveCalls;
