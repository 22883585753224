import React from "react";
import "./CallSummary.css"; 
import CallIcon from "@mui/icons-material/Call";
function CallDetails({data}) {

  if (!data) {
    return <div>No Call</div>; 
  }

  const {  total } = data; 
  return (
    <div className="call-summary">
      <div className="summary-header">
        <i className="tio-user-big"></i>
        {/* <h4 style={{ fontSize: "18px" }}>Call Summary</h4> */}
      </div>

      {/* <div className="table-row header-row data">
        <p className="data1">166</p>
        <p className="data2">4</p>
        <p>David</p>
      </div> */}
      <div className="table-row">
      <p style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
          <CallIcon  style={{ marginRight: "8px", color: "green" }} />
          Total Calls: {total.count}
        </p>
        {/* <p>Total Users Called</p>
        <p>Best Performer</p> */}
      </div>
    </div>
  );
}

export default CallDetails;
