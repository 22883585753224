import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line, Bar } from "react-chartjs-2";
import CallSummary from "./CallSummary";
import CallDetails from "./CallDetails";
import { Link } from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../helper/Constants";
import { useSelector } from "react-redux";
import ExtensionSummary from "./ExtensionSummary";
import IncomingCount from "./IncomingCount";
import OutgoingCount from "./OutgoingCount";
import MissedCount from "./MissedCount";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';  

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function Dashboard() {
  const [lineChartData, setLineChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [loading, setLoading] = useState(true);  // Control loading state
  const [error, setError] = useState(null);
  const user_id = useSelector((state) => state.wallet.userId);
  const [extensionData, setExtensionData] = useState(null);
  const [callsData, setcallsData] = useState(null);
  const [callSummaryData, setCallSummaryData] = useState(null);

  

  const fetchCallSummary = async () => {
    try {
      const response = await CallGETAPI(`api/get-all-cdr-count`);
      if (response?.data?.success) {
        setCallSummaryData(response?.data?.data);
      } else {
        setError("Failed to fetch call summary data");
      }
    } catch (err) {
      setError("Failed to fetch call summary data");
    } finally {
      setLoading(false);  // Data fetching is done
    }
  };

  const fetchCallDetail = async () => {
    try {
      const response = await CallPOSTAPI(`api/get-cdr-date-Call-Processed`, {});

      if (response?.status) {
        const result = response?.data?.data?.dateRange;
        setLineChartData(prepareLineChartData(result));
        setcallsData(result);
      } else {
        setError('Failed to fetch line chart data');
      }
    } catch (err) {
      setError('Failed to fetch line chart data');
    }
  };

  const fetchExtensionData = async () => {
    try {
      const response = await CallPOSTAPI(`api/get-cdr-date-Call-Extension`);
      const result = response?.data?.data;
      if (response.status) {
        setBarChartData(prepareBarChartData(result.dateRange));
        setExtensionData(result);
      } else {
        setExtensionData([]);
      }
    } catch (error) {
      setError('Failed to fetch bar chart data');
    }
  };

  useEffect(() => {
    Promise.all([fetchCallDetail(), fetchExtensionData()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [user_id]);

  const prepareLineChartData = (data) => {
    if (!data || !Array.isArray(data)) {
      return null;
    }

    const labels = data.map(item => item.date);
    const totalCalls = data.map(item => parseInt(item.TotalCallCounts || 0));
    const answeredCalls = data.map(item => parseInt(item.Answere || 0));
    const missedCalls = data.map(item => parseInt(item.misscalls || 0));

    return {
      labels,
      datasets: [
        {
          label: "Total Calls",
          data: totalCalls,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
        {
          label: "Answered Calls",
          data: answeredCalls,
          fill: false,
          borderColor: "#742774",
        },
        {
          label: "Canceled Calls",
          data: missedCalls,
          fill: true,
          backgroundColor: "rgba(88, 191, 106, 0.2)",
          borderColor: "#58BF6A",
        },
      ],
    };
  };

  const prepareBarChartData = (data) => {
    if (!data || !Array.isArray(data)) {
      return null;
    }

    const labels = data.map(item => item.date);
    const totalCalls = data.map(item => parseInt(item.TotalCallCounts || 0));
    const answeredCalls = data.map(item => parseInt(item.Answere || 0));
    const missedCalls = data.map(item => parseInt(item.misscalls || 0));

    return {
      labels,
      datasets: [
        {
          label: "Total Calls",
          data: totalCalls,
          backgroundColor: "#4bc0c0",
        },
        {
          label: "Answered Calls",
          data: answeredCalls,
          backgroundColor: "#9966ff",
        },
        {
          label: "Missed Calls",
          data: missedCalls,
          backgroundColor: "#ff6384",
        },
      ],
    };
  };

  useEffect(() => {
    if (user_id) {
      fetchCallSummary();
    }
  }, [user_id]);

  if (loading) {
    return (
      <div>
        <Skeleton height={40} width={300} />
        <Skeleton height={400} width={"100%"} />
        <Skeleton height={400} width={"100%"} />
      </div>
    );
  }

  if (error) return <div>{error}</div>;

  return (
    <>
      <ToastContainer />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-6">
              <div className="App">
                <CallDetails data={callSummaryData}
                isLoading={loading} 
                 />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="App">
                <IncomingCount data={callSummaryData}
                isLoading={loading} 
                 />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="App">
                <OutgoingCount data={callSummaryData} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="App">
                <MissedCount data={callSummaryData} />
              </div>
            </div>
          </div>
        </section>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-6">
              <div className="App">
                <CallSummary data={callsData} />
              </div>
            </div>
            <div className="col-lg-6">
              <ExtensionSummary 
                data={extensionData?.dateRange} 
                total={extensionData?.total} 
              />
            </div>
          </div>
        </section>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-6">
              <div className="App" style={{ background: "white" }}>
                {lineChartData && <Line key={`line-${JSON.stringify(lineChartData)}`} data={lineChartData} />}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="App" style={{ background: "white" }}>
                {barChartData && <Bar 
                  key={`bar-${JSON.stringify(barChartData)}`}
                  data={barChartData} 
                  options={{
                    responsive: true,
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }} 
                />}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Dashboard;
