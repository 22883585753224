import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toggleSidebar } from "../slices/sidebarSlice";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

import "../App.css";
import { DecryptToken } from "../helper/Constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalance } from "../slices/WalletSlice";
import { FaArrowRight } from "react-icons/fa";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useSIPProvider } from "react-sipjs";
import axios from "axios";
import { io } from "socket.io-client";

const Header = ({ navHandler, openNav,  }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState(null);
  const [showButton, setShowButton] = useState(true); // State to handle button visibility
  const { registerStatus, connectStatus } = useSIPProvider();
  console.log({connectStatus})
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [totalLiveCalls, setTotalLiveCalls] = useState(0);
  // Access incoming call state from Redux store
  const incomingCall = useSelector((state) => state.wallet.incomingCall);
  const liveCalls = useSelector((state) => state.wallet.liveCalls);
  const API_SERVER_URL = 'http://192.155.106.105:5000'; // Replace with your API server URL
  const SOCKET_SERVER_URL = 'http://192.155.106.105:5000'; // Replace with your Socket.IO server URL

  
  const [userId, setUserId] = useState(null);
  const liveCallCount = liveCalls[userId] || 0;
  const showToast = (message, type = "success") => {
    toast[type](message);
  };



  // Function to format date and time
const formatDateTime = (dateTime) => {
  if (!dateTime) return '';
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};



  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const userToken = localStorage.getItem("psx_token");
        const decodedToken = DecryptToken(userToken);

        if (decodedToken) {
          setUserEmail(decodedToken.email);
          setUserId(decodedToken.user_id);
        }
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchUserEmail();
  }, []);
  const [userNumber, setUserNumber] = useState(null);
  const balance = useSelector((state) => state.wallet.balance);
  const sip = useSelector((state) => state.wallet.endDate);
  
  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  const handleLogout = () => {
    const userToken = localStorage.getItem("psx_token");

    if (userToken) {
      localStorage.removeItem("psx_token");
      navigate("/", { replace: true });
      showToast("Logout successfully");
    }
  };

  const openwallet = () => {
    navigate("/wallet");
  };

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    const handleResize = () => {
      setShowButton(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  // ----------------- fetch Live Calls 
  useEffect(() => {
    if (!userId) {
      setError('User ID is required.');
      setLoading(false);
      return;
    }

    /*  
      axios.get(`${API_SERVER_URL}/api/live-calls`, { params: { userId } })
        .then((response) => {
          setCampaigns(response.data);
          const totalCalls = response.data.reduce((acc, campaign) => acc + campaign.callCount, 0); 
          setTotalLiveCalls(totalCalls); 
          setLoading(false);
        })
        .catch((err) => {
          console.error('Error fetching live calls:', err);
          setError('Failed to fetch live calls.');
          setLoading(false);
        });
    */

    // Initialize Socket.IO client
    const socket = io(SOCKET_SERVER_URL);

     // Listen for liveCalls updates
     socket.on('liveCalls', (data) => {
      setCampaigns(data);
      const totalCalls = data.reduce((acc, campaign) => acc + campaign.callCount, 0); // Update total live calls when new data comes in
      setTotalLiveCalls(totalCalls);
    });

    // Join the user-specific room
    socket.emit('join', userId);

    // Listen for FinalCallTbls updates
    socket.on('liveCalls', (data) => {
        console.log({data})
      setCampaigns(data);
    });

    // Handle socket errors
    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
    //   toast.error('Real-time updates disconnected.');
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, [userId]);


  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="row w-100">
          <div className="col-lg-4 d-flex justify-content-start setter">
            <div className="d-flex align-items-center ">
            <div className="nav-link nav-icon logo d-flex align-items-center">
                <Link to="/dashboard" className="d-none d-lg-block text-nowrap">
                  <span style={{ color: "#FF7F00" }}>Live </span>
                  <span style={{ color: "#0083BE" }}>PBX</span>
                </Link>
                <i
                  className="bi bi-list toggle-sidebar-btn"
                  onClick={navHandler} // Yahan toggleSidebar function ko call karenge
                />
                {/* <i
                  className="fa-solid fa-phone-volume"
                  style={{ fontSize: "20px", color: "#07a61a" }}
                  // Yahan toggleSidebar function ko call karenge
                />
                <span
                  style={{
                    fontSize: "12px",
                    color: "000000",
                    paddingLeft: "10px",
                  }}
                >
                 {totalLiveCalls} Live
                </span> */}
                <i
                  className="fa-solid fa-phone-volume"
                  style={{ fontSize: "20px", color: "#0083BE" }}
                  // Yahan toggleSidebar function ko call karenge
                />{" "}
                <span
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    paddingLeft: "10px",
                  }}
                >
                  {/* {totalLiveCalls} Live */}
                   0 Extension Live Calls
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row w-100">
          <div className="col-lg-4 d-flex justify-content-start setter">
            <div className="d-flex align-items-center ">
              <p style={{  color: "rgb(0, 131, 190)" }}>
                User:-
              </p>
              <p style={{ marginLeft: "0rem", color: "rgb(0, 131, 190)" }}>
                {sip}
              </p>
              <p style={{  marginLeft: "5px", display: "inline", color: "rgb(0, 131, 190)" }}>
                {connectStatus ? "Connected" : "Disconnected"}
                {registerStatus ? "Registered" : "Un-Registered"}
              </p>
              <LibraryAddCheckIconv    
                style={{ marginLeft: "5px", color: "rgb(0, 131, 190)" }}
              />
            </div>
          </div>
        </div> */}

        {/* Display "Connect" text */}
        {/* <div className="row w-100">
          <div className="col-lg-4 d-flex justify-content-start setter">
            <div className="d-flex align-items-center ">
              <p style={{ color: "rgb(0, 131, 190)" }}>Connected</p>
            </div>
          </div>
        </div> */}

        {/* End Logo */}
        {/* <div className="search-bar">
          <form
            className="search-form d-flex align-items-center"
            method="POST"
            action="#"
          >
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search" />
            </button>
          </form>
        </div> */}
        {/* End Search Bar */}
        <div className="col-lg-8 d-flex justify-content-end">
          <nav className="header-nav">
            <ul className="d-flex align-items-center">
              {/* <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i className="bi bi-search" />
              </a>
            </li> */}
              {/* End Search Icon*/}
              {/* {{ balance } < 10 && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link nav-icon"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <button type="button" className="btn btn-danger rounded-0">
                      Low Credit
                    </button>
                  </a>
                </li>
              )} */}

              {showButton && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link nav-icon"
                    // href="#"
                    // data-bs-toggle="dropdown"
                  >
                    <button
                      style={{ fontSize: "13px" }}
                      type="button"
                      className="btn btn-danger rounded-0"
                    >
                      Your Plan Will Expire Soon
                    </button>
                  </a>
                </li>
              )}
              {/* <Button variant="outline-danger" className="navbar-item  ">
                <span className="set ">Your</span>
              </Button>

              <Button variant="outline-dark" className="navbar-item ">
                <span className="set">
                  <strong>Expiry</strong>
                </span>
              </Button> */}
              {showButton && (
                <li className="nav-item dropdown">
                  <a className="nav-link nav-icon">
                    <span>
                      <strong style={{ fontSize: "16px" }}>
                      <p>Expire Date: {formatDateTime(sip) || "Not Available"}</p>{/* Display endDate */}
                      </strong>
                    </span>
                  </a>
                </li>
              )}
              {/* <li className="nav-item dropdown">
                <a className="nav-link nav-icon">
                  <i className="fa fa-phone" />
                </a>
              </li> */}
              {/* <Link to="/register">Register</Link> */}
              <li className="nav-item dropdown">
                <Link
                  to="/wallet"
                  className="nav-link nav-icon"
                  data-bs-toggle="false"
                >
                  <i className="bi bi-wallet" />

                  <span style={{ fontSize: "18px" }}>
                    <i className="bi bi-currency-dollar" />
                    <strong>{balance?.toFixed(2)}</strong>
                    {{ balance } < 10 && <h3>Low Credit</h3>}
                  </span>
                </Link>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link nav-icon"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="bi bi-bell" />
                  <span className="badge bg-primary badge-number">4</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  <li className="dropdown-header">
                    You have 4 new notifications
                    <a href="#">
                      <span className="badge rounded-pill bg-primary p-2 ms-2">
                        View all
                      </span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-exclamation-circle text-warning" />
                    <div>
                      <h4>Lorem Ipsum</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>30 min. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-x-circle text-danger" />
                    <div>
                      <h4>Atque rerum nesciunt</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>1 hr. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-check-circle text-success" />
                    <div>
                      <h4>Sit rerum fuga</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>2 hrs. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-info-circle text-primary" />
                    <div>
                      <h4>Dicta reprehenderit</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>4 hrs. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="dropdown-footer">
                    <a href="#">Show all notifications</a>
                  </li>
                </ul>
              </li> */}
              {/* End Notification Nav */}
              <li className="nav-item dropdown pe-3">
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <img
                    src="assets/img/profile-img.jpg"
                    alt="Profile"
                    className="rounded-circle"
                  /> */}
                  {/* <span className="d-none d-md-block dropdown-toggle ps-2">
                    K. Anderson
                  </span> */}
                  {userEmail && (
                    <span className="d-none d-md-block dropdown-toggle ps-2">
                      {/* <FaUserCircle style={{ marginRight: "5px" }} /> */}
                      <span>{`${userEmail.substring(0, 6)}...`}</span>
                    </span>
                  )}
                </a>
                {/* End Profile Iamge Icon */}
                <ul
                  className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"
                  style={{}}
                >
                  <li className="dropdown-header">
                    {/* <h6>Kevin Anderson</h6> */}
                    {userEmail && (
                      <span className="d-none d-md-block ">
                        {/* <FaUserCircle style={{ marginRight: "5px" }} /> */}
                        <span>{`${userEmail}`}</span>
                      </span>
                    )}
                    <span>Web Designer</span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      to="/profile"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className="bi bi-person" />
                      <span>My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/purchaseplan"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className="bi bi-person" />
                      <span>Purchase Plan</span>
                    </Link>
                  </li>

                  {/* <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to="/profile"
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="bi bi-person" />
                    <span>Account Settings</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to="/need-help"
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="bi bi-question-circle" />
                    <span>Need Help?</span>
                  </Link>
                </li> */}

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      to="/"
                      className="dropdown-item d-flex align-items-center"
                      onClick={handleLogout}
                    >
                      <i className="bi bi-box-arrow-right" />
                      <span>Sign Out</span>
                    </Link>
                  </li>
                </ul>
                {/* End Profile Dropdown Items */}
              </li>
              {/* End Profile Nav */}
            </ul>
          </nav>
        </div>
        {/* End Icons Navigation */}
      </header>
    </>
  );
};

export default Header;