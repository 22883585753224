import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const EditPublisherModal = ({ show, onHide, publisher, onUpdate,isLoading }) => {
  const [editingPublisher, setEditingPublisher] = useState(publisher);

  useEffect(() => {
    setEditingPublisher(publisher);
  }, [publisher]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(editingPublisher);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditingPublisher(prev => ({ ...prev, [id]: value }));
  };

  return (
    <Modal show={show} onHide={onHide}  backdrop="static"
    style={{ marginTop: "8rem" }}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Publisher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editingPublisher && (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="Mobile_number">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={editingPublisher.Mobile_number}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Publisher Name</Form.Label>
              <Form.Control
                type="text"
                value={editingPublisher.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <input type="hidden" id="publisher_id" value={editingPublisher.publisher_id} />
            <Button variant="primary" type="submit"disabled={isLoading}>
            {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              Updating...
            </>
          ) : (
            "Update"
          )}
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditPublisherModal;