import React from "react";
import "./CallSummary.css"; 
import MissedCallIcon from "@mui/icons-material/PhoneMissed"; // Missed call icon

function MissedCount({data}) {

  if (!data) {
    return <div>No Call</div>; 
  }

  const {  missed } = data; 
  return (
    <div className="call-summary">
      <div className="summary-header">
        <i className="tio-user-big"></i>
        {/* <h4 style={{ fontSize: "18px" }}>Call Summary</h4> */}
      </div>

      {/* <div className="table-row header-row data">
        <p className="data1">166</p>
        <p className="data2">4</p>
        <p>David</p>
      </div> */}
      <div className="table-row">
      <p style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
          <MissedCallIcon style={{ marginRight: "8px", color: "red" }} />
          Missed Calls: {missed.count}
        </p>
        {/* <p>Total Users Called</p>
        <p>Best Performer</p> */}
      </div>
    </div>
  );
}

export default MissedCount;
